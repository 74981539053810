export interface ReconcileGroupCreated extends ReconcileGroupUpdated {}

export interface ReconcileGroupDeleted extends ReconcileGroupUpdated {}

export interface ReconcileGroupUpdated {
  ReconcileGroupId: string;
  AgreementId: string;
  Name: string;
  AccountIds: string[];
}
export default ReconcileGroupUpdated;

export const isReconcileGroupUpdated = (resource: any): resource is ReconcileGroupUpdated => {
  return resource.AgreementId !== undefined && resource.ReconcileGroupId !== undefined && resource.Name !== undefined;
}