import makeStyles from '@mui/styles/makeStyles';
import CardMedia from '@mui/material/CardMedia'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import MaterialLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import React, { AnchorHTMLAttributes } from 'react'
// import Twitter from '../../images/socialmedia/twitter.svg'
import Facebook from '../../images/socialmedia/facebook.svg'
import LinkedIn from '../../images/socialmedia/linkedin.svg'
import Logo from '../../images/aritma-logo-pink.svg'
import { strings } from '../../utils/localization'



const useStyles = makeStyles((theme: any) => ({
  footer: {
    backgroundColor: theme.palette.fourth.main,
    padding: '25px 10px 0 10px',
    marginTop: 'auto',
    width: '100%',
  },
  link: {
    color: theme.palette.grey[600],
  },
  logo: {
    width: '13em',
    maxWidth: '13em',
  },
  title: {
    fontFamily: 'Inter',
    color: '#FFF',
    fontWeight: 600,
    fontSize: '1.5em',
    marginBottom: '.6em',
  },
  text: {
    fontFamily: 'Inter',
    color: '#DDD',
    fontWeight: 500,
    lineHeight: '2.3em',
    fontSize: '1.1em',
  },
  socialMedia: {
    width: '2.5em',
    maxWidth: '2.5em',
    margin: '3em 1em 0 0',
    display: 'inline-block',
  },
  logoContainer: {
    marginBottom: '5em',
    [theme.breakpoints.down('sm')]: {
      order: 4,
    },
  },
}))

const footers = [
  {
    title: 'Features',
    description: [{ label: 'Reconciliation', href: '#' },
    { label: 'API', href: '#' },
    { label: 'Documentation', href: '#' }],
  },
  {
    title: 'About us',
    description: [{ label: 'Aritma', href: 'https://www.aritma.com' }],
  },
]

const PageFooter = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Container maxWidth='lg' className={classes.footer}>
        <Grid container justifyContent='space-between'>
          <Grid
            className={classes.logoContainer}
            item
            xs={12}
            sm={6}
            md={3}
          >
            <CardMedia
              alt='Artima Logo'
              title='Aritma Logo'
              component='img'
              className={classes.logo}
              image={Logo}
            />
            <Typography
              style={{ marginTop: '5em' }}
              className={classes.text}
            >
              <Copyright />
              <br />
              Terms of Service | Privacy Policy
            </Typography>
          </Grid>

          {footers.map((footer, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={3}
              style={{ marginBottom: '5em' }}
            >
              <Typography
                className={classes.title}
                variant='h1'
                gutterBottom
              >
                {footer.title}
              </Typography>
              <Grid>
                {footer.description.map((item, index) => {
                  const props: AnchorHTMLAttributes<HTMLAnchorElement> = {}
                  if (item.href.includes('http')) {
                    props.target = '_blank'
                    props.rel = 'noopener'
                  }
                  return (
                    <Grid item key={index}>
                      <MaterialLink
                        className={classes.text}
                        variant='subtitle1'
                        href={item.href}
                        target={props.target}
                        rel={props.rel}
                      >
                        {item.label}
                      </MaterialLink>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={{ marginBottom: '5em' }}
          >
            <Typography
              className={classes.title}
              variant='h1'
              gutterBottom
            >
              {strings('footer').getString('contactTitle')}
            </Typography>
            <Grid>
              <Grid item>
                <Typography className={classes.text}>
                  {strings('footer').getString('contactSubtitle')}
                </Typography>
                <SocialMedias />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
export default PageFooter

const Copyright = () => {
  return (
    <span>
      © {new Date().getFullYear()}{' '}
      <MaterialLink
        color='inherit'
        href='https://www.zdata.no/'
        target='_blank'
        rel='noopener'
      >
        Aritma
      </MaterialLink>
      . All rights reserved.{' '}
    </span>
  )
}

const SocialMedias = () => {
  const classes = useStyles()
  return (
    <>
      <MaterialLink
        color='inherit'
        href='https://no.linkedin.com/company/zdata'
        target='_blank'
        rel='noopener'
      >
        <CardMedia
          title='LinkedIn'
          component='img'
          image={LinkedIn}
          className={classes.socialMedia}
        />
      </MaterialLink>
      {/* <MaterialLink color='inherit' href='#' target='_blank'>
                <CardMedia
                    title='Twitter'
                    component='img'
                    image={Twitter}
                    className={classes.socialMedia}
                />
            </MaterialLink> */}
      <MaterialLink
        color='inherit'
        href='https://www.facebook.com/zdataas/'
        target='_blank'
        rel='noopener'
      >
        <CardMedia
          title='Facebook'
          component='img'
          image={Facebook}
          className={classes.socialMedia}
        />
      </MaterialLink>
    </>
  )
}
