import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VoucherAttachment } from '../models/interfaces/Voucher';
import { RootState } from '../store';

interface BookkeepingState {
    voucherAttachments: VoucherAttachment[] | undefined;
}

const initialState: BookkeepingState = {
    voucherAttachments: undefined,
};

const bookkeepingSlice = createSlice({
    name: 'bookkeeping',
    initialState,
    reducers: {
        setVoucherAttachments: (state, action: PayloadAction<VoucherAttachment | undefined>) => {
            if (action.payload) {
                const currentState = state.voucherAttachments?.find(
                  (x) => x.voucherId === action.payload?.voucherId
                );
                if (currentState) {
                    currentState.attachments = action.payload.attachments;
                } else {
                    state.voucherAttachments = [...(state.voucherAttachments || []), action.payload];
                }
            } else {
                state.voucherAttachments = action.payload;
            }
        },
        clearAllVoucherAttachments: (state) => {
            state.voucherAttachments = undefined; // Or use `[]` if you want an empty array instead
        },
    },
});

export default bookkeepingSlice.reducer;

export const { setVoucherAttachments, clearAllVoucherAttachments } = bookkeepingSlice.actions;

export const selectAllVoucherAttachments = (state: RootState) => state.bookkeeping.voucherAttachments;
export const selectVouchersAttachments = (state: RootState, voucherId: string) =>
  state.bookkeeping.voucherAttachments?.find((x) => x.voucherId === voucherId);
