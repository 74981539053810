import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { setUser, toOidcUser } from '../store/reducers/userSlice';
import store from '../store/store';

interface AuthWrapperProps {
  children: React.ReactNode
}


export const AuthWrapper =  ({ children }: AuthWrapperProps) => {
  const auth = useAuth()

  useEffect(() => {
    if (!auth.isLoading && auth.user) {
      store.dispatch(setUser(toOidcUser(auth.user)));
    }
  }, [auth.isLoading, auth.user])

  return <>{children}</>
}