import makeStyles from '@mui/styles/makeStyles';
const drawerWidth = 200;
const toolbarMinHeight = 60;

const topBarStyles = makeStyles((theme: any) => ({
  toolbar: {
    paddingRight: 24,
    minHeight: toolbarMinHeight,

    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    }
  },
  appBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.topBar.main,
    color: theme.palette.topBar.contrastText
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.topBar.contrastText,
    "&:hover": {
      color: '#288bc4'
    },
  },
  logoContainer: {
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // }
  },
  logo: {
    padding: 12,
    width: 120,
    margin: 'auto',
    paddingRight: 10
  }
}));
export default topBarStyles;