import { AuthProviderProps } from 'react-oidc-context'

export const IDENTITY_CONFIG: AuthProviderProps = {
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID as string,
  redirect_uri: window.location.origin + process.env.REACT_APP_REDIRECT_URL as string,
  response_type: 'code',
  scope: 'api openid profile bankservice',
  authority: process.env.REACT_APP_AUTH_URL as string,
  silent_redirect_uri: window.location.origin + process.env.REACT_APP_SILENT_REDIRECT_URL as string,
  post_logout_redirect_uri: window.location.origin + process.env.REACT_APP_LOGOFF_REDIRECT_URL as string,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
}