import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { usePrefetch as usePrefetchReconcileGroups } from '../../store/reducers/reconcileApi/reconcileGroupApi';
import { List } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/reducers/userSlice';

export const MainListItems = () => {
  const user = useSelector(selectUser);
  const prefetchReconcileGroups = usePrefetchReconcileGroups('getReconcileGroups');

  const handlePrefetchReconcileGroups = () => {
    if (user && user.access_token)
      prefetchReconcileGroups(undefined, { ifOlderThan: 60 });
  };

  return (
    <List>
      <ListItem component={Link} to="/dashboard" onMouseOver={handlePrefetchReconcileGroups}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem component={Link} to="/approve">
        <ListItemIcon>
          <AssignmentTurnedInIcon />
        </ListItemIcon>
        <ListItemText primary="Approve" />
      </ListItem>
      <ListItem component={Link} to="/reports">
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
      <ListItem component={Link} to="/import">
        <ListItemIcon>
          <CloudUploadIcon />
        </ListItemIcon>
        <ListItemText primary="Import" />
      </ListItem>
    </List>
  );
};