import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ActivateAccountsState {
  activeStep: number;
}

const initialState: ActivateAccountsState = {
  activeStep: 0
};

const activateAccountsSlice = createSlice({
  name: 'activateAccounts',
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
  }
});
export default activateAccountsSlice.reducer

export const { setActiveStep } = activateAccountsSlice.actions;
export const selectActiveStep = (state: RootState) => state.activateAccounts.activeStep;
