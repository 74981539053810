// import { setTheme } from '../../store/actions/theme';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectTheme, setTheme, ThemeType } from '../../store/reducers/themeSlice';
import useWidth from '../../utils/useWidth';


const useStyles = makeStyles((theme) => ({
  container: {
    width: 'auto',
    minWidth: 100
  },
  sun: {
    fill: '#8e9194'
  },
  selectedSun: {
    fill: '#fdc949'
  },
  moon: {
    fill: '#8e9194'
  },
  selectedMoon: {
    fill: '#5597ef'
  }
}));


const DarkThemeSlider = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const width = useWidth();
  const themeType = useSelector(selectTheme);

  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    if (!checked) {
      dispatch(setTheme(ThemeType.LIGHT_THEME))
      localStorage.setItem('themeType', ThemeType.LIGHT_THEME.toString())
    } else {
      dispatch(setTheme(ThemeType.DARK_THEME))
      localStorage.setItem('themeType', ThemeType.DARK_THEME.toString())
    }
  }

  useEffect(() => {
    setChecked(themeType === ThemeType.LIGHT_THEME)
  }, [themeType])

  useEffect(() => {
    const themeTypeFromStorage = Number(localStorage.getItem('themeType'));
    dispatch(setTheme(themeTypeFromStorage))
  }, [dispatch])

  const ThemeToggle = () => {
    if (width === 'xs') {
      return (
        <IconButton
          aria-checked={themeType === ThemeType.LIGHT_THEME}
          onClick={handleChange}
          aria-label="changeTheme"
          size="large">
          {(themeType === ThemeType.LIGHT_THEME) ?
            <Brightness5Icon className={classes.selectedSun} /> :
            <Brightness4Icon className={classes.selectedMoon} />
          }
        </IconButton>
      );
    } else {
      return (
        <Grid container className={classes.container} spacing={0} justifyContent="flex-end" alignItems="center">
          <Brightness3Icon className={checked ? classes.moon : classes.selectedMoon} />
          <Switch
            checked={checked}
            onChange={handleChange}
            color="secondary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <Brightness5Icon className={checked ? classes.selectedSun : classes.sun} />
        </Grid>
      );
    }
  }

  return (
    <ThemeToggle />
  );
}
export default DarkThemeSlider;