
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankTransaction, ErpTransaction } from '../models/interfaces/Transaction';
import { RootState } from '../store';

export interface DialogData{
    row : (ErpTransaction | BankTransaction)
    isOpen : boolean
}

export const toDialogData = <D extends DialogData>(
    dialogData: D
    ) => dialogData;

export interface DialogState{
    data: DialogData,
    name: string
}

export interface DialogsState {
    selectedDialogState : DialogState | undefined
}


const initialState: DialogsState = {
    selectedDialogState : undefined
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setSelectedDialogState: (state, action: PayloadAction<DialogState | undefined>) => {
      state.selectedDialogState = action.payload;
    },
  }
});
export default dialogSlice.reducer

export const { setSelectedDialogState } = dialogSlice.actions;
export const selectSelectedDialogState = (state: RootState) => state.dialog.selectedDialogState;
