import {
  HubConnection,
} from '@microsoft/signalr';
import { Dispatch, Middleware, PayloadAction } from '@reduxjs/toolkit';
import reconcileApi from '../store/reducers/reconcileApi/reconcileApi';
import { store } from '../store/store';
import Event from '../store/models/events/Event';
import signalRService, { SignalREventSubscription } from './signalRService';
import { isQuery } from '../utils/rtkQueryHelpers';
import {isReconcileGroup, isReconcileGroups} from '../store/models/interfaces/ReconcileGroup';
import ReconcileLinkCreated from '../store/models/events/ReconcileLinkCreated';
import ReconcileLinkDeleted from '../store/models/events/ReconcileLinkDeleted';
import ReconcileTransactionsAdded, { isReconcileTransactionsAdded } from '../store/models/events/ReconcileTransactionsAdded';
import ReconciliationUpdated from '../store/models/events/ReconciliationUpdated';
import { ReconcileGroupCreated, ReconcileGroupDeleted, isReconcileGroupUpdated } from '../store/models/events/ReconcileGroupUpdated';
import { FullTagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import nameof from "ts-nameof.macro";

type EventTypes = ReconcileLinkCreated | ReconcileLinkDeleted | ReconcileTransactionsAdded | ReconciliationUpdated | ReconcileGroupCreated | ReconcileGroupDeleted
const getEventType = (event: Event<EventTypes>) => event.EventType.split('.').pop();

const reconcileGroupEventsCallback = (event: Event<EventTypes>) => {
  const tags: (string | FullTagDescription<string>)[] = [
    { type: 'banktransaction', id: event.Data.ReconcileGroupId },
    { type: 'erptransaction', id: event.Data.ReconcileGroupId },
    { type: 'reconcilegroup', id: event.Data.ReconcileGroupId }
  ];
  if(getEventType(event) === nameof<ReconcileTransactionsAdded>() && isReconcileTransactionsAdded(event.Data)){
    tags.push({type: 'account', id: event.Data.AccountId})
  }
  if((getEventType(event) === nameof<ReconcileGroupCreated>() || getEventType(event) === nameof<ReconcileGroupDeleted>()) && isReconcileGroupUpdated(event.Data)){
    tags.push({type: 'account', id: event.Data.ReconcileGroupId}, {type: 'account', id: event.Data.AgreementId})
  }
  store.dispatch(reconcileApi.util.invalidateTags(tags));
};

export const signalRMiddleware: Middleware = api => {
  const subscriptionSet = new Set();
  let hubConnection: HubConnection | undefined;
  return (next: Dispatch<PayloadAction<any>>) => (action: PayloadAction<any>) => {
    if (action.type === 'user/setUser' && action.payload.access_token) {
      hubConnection = signalRService.setupSignalRConnection(action.payload.access_token);
    }

    if (hubConnection && action.payload && isQuery(action)) {
      let resources = action.payload
      if (isReconcileGroup(resources)) {
        if (!subscriptionSet.has(resources.resourceUri)) {
          const reconcileGroupSubscriptions = getResourceSubscriptions(resources.resourceUri, reconcileGroupEvents);
          subscriptionSet.add(resources.resourceUri)
          signalRService.subscribeToEventBatch(hubConnection, reconcileGroupSubscriptions, reconcileGroupEventsCallback);
        }
      }
      if (isReconcileGroups(resources)) {
        let subscriptionBatch: SignalREventSubscription[] = []
        for (let i = 0; i < resources.length; i++) {
          if (!subscriptionSet.has(resources[i].resourceUri)) {
            const reconcileGroupSubscriptions = getResourceSubscriptions(resources[i].resourceUri, reconcileGroupEvents);
            subscriptionBatch = [...subscriptionBatch, ...reconcileGroupSubscriptions];
            subscriptionSet.add(resources[i].resourceUri);
          }
        }
        if (subscriptionBatch.length > 0){
          signalRService.subscribeToEventBatch(hubConnection, subscriptionBatch, reconcileGroupEventsCallback);

        }
      }
    }

    return next(action);
  }
};

const reconcileGroupEvents = [
  "control.reconcileLink.created",
  "control.reconcileLink.deleted",
  "control.reconciliation.updated",
  "control.transactions.added",
  "control.postingDate.updated",
  "control.reconcileGroup.created",
  "control.reconcileGroup.deleted"]

const getResourceSubscriptions = (resourceUri : string, eventNames: string[]) => {
  return eventNames.map(eventName => ({
    eventName: eventName,
    resourceUri: resourceUri
  } as SignalREventSubscription))
}