import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface AgreementState {
  selectedAgreement: string | undefined;
}

const initialState: AgreementState = {
  selectedAgreement: undefined
};

const agreementSlice = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    setSelectedAgreement: (state, action: PayloadAction<string | undefined>) => {
      state.selectedAgreement = action.payload;
    },
  }
});
export default agreementSlice.reducer

export const { setSelectedAgreement } = agreementSlice.actions;
export const selectSelectedAgreement = (state: RootState) => state.connection.selectedAgreement;
