import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import type { RootState } from '../../store';
import { AlertStatus } from '../alertsSlice';

const baseReconcileQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_RECONILE_API_URL + "/api/v1/",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).user.access_token

    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  },
});

export interface AlertBody{
  title? : string,
  status? : AlertStatus
}

export interface AlertOptions {
  getCustomAlertMessage? : (input : any) => AlertBody | string,
}

interface Meta {
  alertTitle? :  string,
  alertStatus? : AlertStatus
}

export const reconcileBaseQuery : BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  AlertOptions & {},
  Meta & FetchBaseQueryMeta
> = async (args, api, extraOptions) => {

  const result = await baseReconcileQuery(args,api,extraOptions);

  if(extraOptions && extraOptions.getCustomAlertMessage){
    return returnResultWithAlert(result, extraOptions);
  }

  return result;
}
export default reconcileBaseQuery;



const returnResultWithAlert = (result : any, extraOptions : AlertOptions & {}) => {
  if(extraOptions.getCustomAlertMessage){
    const alertInfo = extraOptions.getCustomAlertMessage(result);
    let alertTitle : string | undefined;
    let alertStatus : AlertStatus | undefined;

    if(typeof alertInfo !== "string"){
      const alertBody = alertInfo as AlertBody;
      alertTitle = alertBody.title;
      alertStatus = alertBody.status
    }else{
      alertTitle = alertInfo;
    }


    return {
      ...result,
      meta: result.meta && { ...result.meta, alertTitle : alertTitle, alertStatus : alertStatus },
    }
  }
  return result;
}