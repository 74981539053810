import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';

const ProfileMenu = () => {
  const auth = useAuth()

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  }

  const handleClose = (event: MouseEvent | TouchEvent | undefined) => {
    if (event && anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  }

  const handleListKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const redirectToProfile = () => {
    setOpen(false);
    var redirectUrl = process.env.REACT_APP_AUTH_URL ?? "" + process.env.REACT_APP_IDENTITY_PROFILE ?? "";
    window.location.href = redirectUrl
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (anchorRef.current && prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        color="inherit"
        ref={anchorRef}
        aria-label='Profile Menu'
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="large">
        <AccountCircleIcon style={{ fontSize: 30 }} />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement={"bottom-end"}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
          // style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <div>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={redirectToProfile}>Profile</MenuItem>
                    <MenuItem onClick={() => auth.signoutRedirect()}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
export default ProfileMenu;