import CloseIcon from '@mui/icons-material/Close';
import { Collapse, IconButton, LinearProgress, Typography } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as AlertState, AlertStatus, removeAlert, selectAlerts } from '../../store/reducers/alertsSlice';

const alertStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    alignItems: 'center',
    position: 'fixed',
    alignSelf: 'center',
    zIndex: 100
  },
  alert: {
    opacity: 0.6,
    marginBottom: theme.spacing(1)
  },
  reportAlert: {
    marginBottom: theme.spacing(1),
    fontSize: 15,
    '& .MuiAlert-message': {
      alignSelf: 'center',
      padding: 0
    }
  }
}));


const AlertContainer = () => {
  const classes = alertStyles();

  return (
    <Container maxWidth="sm" className={classes.container}>
      <CreateResourceAlertList />
    </Container>
  )
}
export default AlertContainer;

const CreateResourceAlertList = () => {
  const alerts = useSelector(selectAlerts);

  return (
    <Fragment>
      {alerts.map((a: AlertState) =>
        <div key={a.alertId}>
          <LoadingAlert
            status={a.status}
            title={a.title}
            message={a.message}
            alertId={a.alertId}
            alertLink={a.alertLink}
          />
        </div>
      )}
    </Fragment>

  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 2,
    width: '100%',
    alignItems: 'center',
    '& .MuiAlert-message': {
      padding: 2
    }
  },
  loadingAlert: {
    alignItems: 'center',
    borderRadius: '4px 4px 0px 0px',
  },
  alert: {
    alignItems: 'center',
    transition: '500ms',
    animation: `$popout 500ms ${theme.transitions.easing.easeInOut}`,
    borderRadius: 4,
  },
  progress: {
    borderRadius: '0px 0px 4px 4px',
  },
  title: {
    fontWeight: 500
  },
  "@keyframes popout": {
    "0%": {
      transform: 'scale(1)'
    },
    "50%": {
      transform: 'scale(1.05)'
    },
    "100%": {
      transform: 'scale(1)'
    }
  }
}));

const alertFadeTime = 10000;

const LoadingAlert = ({ status, title, message, alertId }: AlertState) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const [fadeTimeout, setFadeTimeout] = useState<NodeJS.Timeout | undefined>(undefined);

  const fadeAlert = useCallback((alertId: string) => {
    setOpen(false);
    setTimeout(() => {
      dispatch(removeAlert({ id: alertId }));
    }, 500);
  }, [dispatch, setOpen])

  useEffect(() => {
    if (fadeTimeout)
      clearTimeout(fadeTimeout);

    if (!isPending(status)) {
      let newFadeTimeout = setTimeout(() => {
        fadeAlert(alertId)
      }, alertFadeTime);

      setFadeTimeout(newFadeTimeout)
    }

  }, [alertId, status, fadeAlert])

  return (
    <Collapse in={open}>
      <div className={classes.root}>
        <Alert
          className={isPending(status) ? classes.loadingAlert : classes.alert}
          variant="standard"
          severity={getSeverity(status)}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <Typography className={classes.title}>
            {title}
          </Typography>
          {message && message}
        </Alert>
        {isPending(status) && <LinearProgress className={classes.progress} />}
      </div>
    </Collapse>
  )
}

const isPending = (status: AlertStatus) => status === AlertStatus.Loading || status === AlertStatus.Deleting;

const getSeverity = (status: AlertStatus) => {
  switch (status) {
    case AlertStatus.Loading: return "info" as AlertColor;
    case AlertStatus.Deleting: return "info" as AlertColor;
    case AlertStatus.Success: return "success" as AlertColor;
    case AlertStatus.Failed: return "error" as AlertColor;
    case AlertStatus.Warning : return "warning" as AlertColor
  }
}
