import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FileImportFile } from '../models/interfaces/FileImport';
import { RootState } from '../store';

interface FileImportState {
  pendingFiles: FileImportFile[];
}

const initialState: FileImportState = {
  pendingFiles: []
};

const fileImportSlice = createSlice({
  name: 'fileImport',
  initialState,
  reducers: {
    setPendingFiles: (state, action: PayloadAction<FileImportFile[]>) => {
      state.pendingFiles = action.payload;
    },
    addPendingFiles: (state, action: PayloadAction<FileImportFile[]>) => {
      state.pendingFiles = [...state.pendingFiles, ...action.payload];
    },
    removePendingFile: (state, action: PayloadAction<string>) => {
      state.pendingFiles = state.pendingFiles?.filter((f) => f.filename !== action.payload);
    },
  }
});
export default fileImportSlice.reducer

export const { setPendingFiles, removePendingFile, addPendingFiles } = fileImportSlice.actions;
export const selectPendingFiles = (state: RootState) => state.fileImport.pendingFiles;
