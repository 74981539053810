import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import MaterialLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link, matchPath, useLocation } from "react-router-dom";
import { pageRoutes } from './PageContent';

const useStyles = makeStyles((theme) => ({
  container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2)
  },
  link:{
      color: theme.palette.text.secondary,
      textDecoration: 'none'
  },
}));

const BreadcrumbsContainer = () => {
  const classes = useStyles();
  const location = useLocation();
  let routes = pageRoutes.filter(p => p.path !== "*").map((p, i) => ({ name: p.name, 
      path: matchPath({
          path: p.path,
          end: p.end
      }, location.pathname),
      simplePath: p.path,
      showCrumbs: p.showCrumbs,
      width: p.width
  })).filter(r => r.path);

  let existingPath = new Set();
  routes = routes.filter(route => {
      let k = route.path?.pathname;
      return existingPath.has(k) ? false : existingPath.add(k);
  });

  let crumbs = routes.sort((a, b) => a.simplePath.length - b.simplePath.length);
  const noCrumbs = !crumbs[routes.length-1].showCrumbs;
  
  const maxWidth = crumbs[routes.length-1].width;
  return (
      <div>
          {!noCrumbs && crumbs.length > 1 ?
              <Container maxWidth={maxWidth ?? "lg"} className={classes.container}>
                  <Breadcrumbs className={classes.link} separator="›" aria-label="Breadcrumb">
                      {crumbs.map((crumb, index) => crumb.showCrumbs && index === crumbs.length - 1 ? 
                          <Typography color="textPrimary" key={crumb.name}>
                              {crumb.name}
                          </Typography>
                          : (crumb.path) &&
                          <MaterialLink component={Link} underline="hover" color="inherit" to={crumb.path.pathname} key={crumb.name} >
                              {crumb.name}
                          </MaterialLink>
                      )}
                  </Breadcrumbs> 
              </Container>: <React.Fragment/>
          }
      </div>
  );
}
export default BreadcrumbsContainer