import makeStyles from '@mui/styles/makeStyles';
import { lazy, Suspense } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import { AuthHandler } from '../../auth/AuthHandler';
import { AuthWrapper } from '../../auth/AuthWrapper';
import PrivateRoute from '../../auth/PrivateRoute';
import CenterSpinner from '../widgets/CenterSpinner';
import AlertContainer from './AlertContainer';
import BreadcrumbsContainer from './BreadcrumbsContainer';
import PageFooter from './PageFooter';
import ReconcileGroupComponent from './ReconcileGroupComponent';

const ReconcileViewLazy = lazy(() => import('../../pages/reconcile/Reconcile'));
const Dashboard = lazy(() => import('../../pages/dashboard/Dashboard'));
const Reports = lazy(() => import('../../pages/reports/Reports'));
const Approve = lazy(() => import('../../pages/approve/Approve'));
const ImportBase = lazy(() => import('../../pages/importfile/ImportBase'));
const ActivateAccount = lazy(() => import('../../pages/activateAccounts/ActivateAccount'));
const Home = lazy(() => import('../../pages/home/Home'));
const ReconcileGroupSettingsLazy = lazy(() => import('../../pages/reconcileGroup/ReconcileGroupSettings'));
const NotFound = lazy(() => import('../../pages/NotFound'));

const ReconcileGroupSettings = () => <ReconcileGroupComponent component={<ReconcileGroupSettingsLazy/>}/>
const ReconcileView = () => <ReconcileGroupComponent component={<ReconcileViewLazy/>}/>

const styles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  }
}));

const PageContent = () => {
  const location = useLocation()

  const classes = styles()
  const routes = (
    <AuthWrapper>
      <Suspense fallback={<CenterSpinner />}>
        <Routes>
          <Route path="/signin-oidc" element={<AuthHandler />} />

          {pageRoutes.map(({ path, Component, publicPage }, key) => (
            publicPage ? 
              <Route path={path} key={key} element={<Component/>} />
            : <Route path={path} key={key} element={<PrivateRoute><Component/></PrivateRoute>} />
          ))}

        </Routes>
      </Suspense>
    </AuthWrapper>
  );

  return (
    <main className={classes.content}>
      {location.pathname !== '/' && (
        <>
          <div className={classes.appBarSpacer} />
          <AlertContainer />
          <BreadcrumbsContainer />
        </>
      )}
      {routes}
      {location.pathname === '/' && <PageFooter />}
    </main>
  )
}
export default PageContent;


type ContainerWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false | undefined;
export interface PageRoute {
  end?: boolean;
  path: string;
  name?: string | undefined;
  Component?: any;
  showCrumbs?: boolean;
  width?: ContainerWidth;
  publicPage?: boolean;
}

export const pageRoutes: Array<PageRoute> = [
  { path: "/dashboard", end: false, name: "Dashboard", Component: Dashboard, showCrumbs: true },
  { path: "/dashboard/reconcile/:reconcileGroupId", end: false, name: "Reconcile", Component: ReconcileView, showCrumbs: true, width: false },
  { path: "/dashboard/reconcile/:reconcileGroupId/settings", end: false, name: "Settings", Component: ReconcileGroupSettings, showCrumbs: true },
  { path: "/reports", end: false, name: "Reports", Component: Reports, showCrumbs: true },
  { path: "/approve", end: false, name: "Approve", Component: Approve, showCrumbs: true, width: false },
  { path: "/import", end: false, name: "Import", Component: ImportBase, showCrumbs: true },
  { path: "/reconcile-group/create", end: true, name: "Create Reconcile Group", Component: ActivateAccount, showCrumbs: true },

  { path: "/", end: false, name: "Home", Component: Home, showCrumbs: false, publicPage: true },
  { path: "*", end: true, name: "Not Found", Component: NotFound, showCrumbs: false, publicPage: true },
] 