import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import ReconcileGroup from '../models/interfaces/ReconcileGroup';
import { ReconcileGroupConfig } from '../models/interfaces/ReconcileGroupConfig';
import { RootState } from '../store';

const getDateForMonth = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return moment(firstDay).utc(true).toISOString();
}

interface ReconcileGroupState {
  selectedReconcileGroup: ReconcileGroup | undefined;
  newReconcileGroupConfig: ReconcileGroupConfig | undefined;
}

const initialState: ReconcileGroupState = {
  selectedReconcileGroup: undefined,
  newReconcileGroupConfig: { date: getDateForMonth(), name: "" },
};

const reconcileGroupSlice = createSlice({
  name: 'reconcileGroup',
  initialState,
  reducers: {
    setSelectedReconcileGroup: (state, action: PayloadAction<ReconcileGroup | undefined>) => {
      state.selectedReconcileGroup = action.payload;
    },
    setNewReconcileGroupConfig: (state, action: PayloadAction<ReconcileGroupConfig | undefined>) => {
      state.newReconcileGroupConfig = action.payload
    }
  }
});
export default reconcileGroupSlice.reducer

export const { setSelectedReconcileGroup, setNewReconcileGroupConfig } = reconcileGroupSlice.actions;
export const selectSelectedReconcileGroup = (state: RootState) => state.reconcileGroup.selectedReconcileGroup;
export const selectNewReconcileGroupConfig = (state: RootState) => state.reconcileGroup.newReconcileGroupConfig;
