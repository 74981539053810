import ReconcileGroup from '../../models/interfaces/ReconcileGroup';
import { CheckReconcileGroupPeriodRequest, CreateReconcileGroupRequest, CreateReconcileGroupRequestBody } from '../../models/requests/CreateReconcileGroupRequest';
import reconcileApi, { providesList } from './reconcileApi';

export const reconcileGroupApi = reconcileApi.injectEndpoints({
  endpoints: (build) => ({

    getReconcileGroup: build.query<ReconcileGroup, string>({
      query: (reconcileGroupId) => `reconcilegroups/${reconcileGroupId}`,
      providesTags: (result, error, arg) => [{ type: 'reconcilegroup', id: arg }]
    }),

    getReconcileGroups: build.query<ReconcileGroup[], void>({
      query: () => 'reconcilegroups/detailed',
      providesTags: (result) => providesList(result, 'reconcilegroup'),
    }),
    getReconcileGroupPeriodIsValid : build.query<boolean, CheckReconcileGroupPeriodRequest>({
      query : ({reconcileGroupId, period}) => `reconcilegroups/${reconcileGroupId}/period/${period}/validate`,
      keepUnusedDataFor :30,
      providesTags : (result, isError, arg) => [{type : 'reconcileGroupPeriod', id :  `${arg.reconcileGroupId}-${arg.period}` }]
    }),
    createReconcileGroup: build.mutation<void, CreateReconcileGroupRequest>({
      query: ({ agreementId, name, accounts, startDate, isCurrencyAccount }) => {
        const body: CreateReconcileGroupRequestBody = {
          name,
          accounts,
          startDate,
          isCurrencyAccount
        }
        return {
          url: `agreements/${agreementId}/reconcilegroups`,
          body: body,
          method: 'POST'
        }
      },
      async onCacheEntryAdded(createReconcileGroupRequest, { dispatch }) {
        // Timeout for cache invalidation because ZAM uses a few seconds to propagate changes.
        setTimeout(() => {
          dispatch(reconcileGroupApi.util.invalidateTags(['reconcilegroup']))
        }, 10000)
      },
    }),
    deleteReconcileGroup: build.mutation<void, string>({
      query: (reconcileGroupId) => ({
        url: `reconcilegroups/${reconcileGroupId}`,
        method: 'DELETE'
      }),
      // async onQueryStarted(reconcileGroupId, { dispatch, queryFulfilled }) {
      //   const patchResult = dispatch(
      //     reconcileGroupApi.util.updateQueryData('getReconcileGroups', undefined, (draft) => {
      //       return draft.filter(s => s.id !== reconcileGroupId)
      //     })
      //   )
      //   queryFulfilled.catch(patchResult.undo)
      // },
      invalidatesTags: (result, isError, reconcileGroupId) => [{ type: 'reconcilegroup', id: reconcileGroupId }]
    })
  }),
})

export const { useGetReconcileGroupQuery, useGetReconcileGroupsQuery, useCreateReconcileGroupMutation, useDeleteReconcileGroupMutation, useGetReconcileGroupPeriodIsValidQuery, usePrefetch } = reconcileGroupApi


