export const groupByNested = (keys: string[]) => (array: any[]) =>
  array.reduce((objectsByKeyValue, obj) => {
    // Instead of creating a unique key for each grouped by values, we are now traversing (and building) 
    // the whole object structure for every array value:
    keys.reduce((builder, key, index) => {
      if (index !== keys.length - 1) {
        // Building the nested grouped by structure
        builder[obj[key]] = builder[obj[key]] || {};
      } else {
        // Appending the current object at the leaf node
        builder[obj[key]] = (builder[obj[key]] || []).concat(obj);
      }
      return builder[obj[key]];
    }, objectsByKeyValue);

    return objectsByKeyValue;
  }, {});

export const removeElement = (array: any[], element: any) => 
  array.filter(item => item !== element);

export const removeElements = (array: any[], elements: any[]) => 
  array.filter(item => !elements.includes(item));


export const mergeArraysNoDupliates = (array1: any[], array2: any[]) => {
  return array1.concat(array2.filter((item) => array1.indexOf(item) < 0));
}

export const concatenatePreviousWithNext = (array: any[]) => {
  return array?.map((el, index) => {
    return (array[index - 1]) ? array.slice(0, index + 1).join('|') : el;
  })
}

export const groupBy = <K, V>(array : V[], keyGetter : (input : V) => K) => {
  const map = new Map<K, V[]>();
  array.forEach((item) => {
       const key = keyGetter(item);
       const collection = map.get(key);
       if (!collection) {
           map.set(key, [item]);
       } else {
           collection.push(item);
       }
  });
  return map;
}