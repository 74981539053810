import {
    JsonHubProtocol,
    HubConnectionState,
    HubConnectionBuilder,
    LogLevel,
    HubConnection,
    IHttpConnectionOptions
} from '@microsoft/signalr';
import Event from '../store/models/events/Event';

export interface SignalREventSubscription {
    eventName: string;
    resourceUri: string;
}

const signalRService = {
    subscribeToEventBatch: async (connection: HubConnection, signalREventSubscriptions: SignalREventSubscription[], callback: (message: Event<any>) => void) => {
        try {
            await connection.invoke("SubscribeToEventBatch", signalREventSubscriptions);
            console.log(`Created ${signalREventSubscriptions.length} event subscriptions`);
            const eventNames = [...new Set(signalREventSubscriptions.map((es) => es.eventName) as string[])]
            eventNames.forEach(eventName => {
                connection.on(eventName, callback);
            });
        } catch (error) {
            console.error("Failed to create subscription: " + error);
        }
    },

    subscribeToEvent: async (connection: HubConnection, signalREventSubscription: SignalREventSubscription, callback: (message: Event<any>) => void) => {
        try {
            await connection.invoke("SubscribeToEvent", signalREventSubscription);
            console.log("Subscribed to " + signalREventSubscription.resourceUri);
            connection.on(signalREventSubscription.eventName, callback);
        } catch (error) {
            console.error("Failed to create subscription: " + error);

        }
    },

    startSignalRConnection: async (connection: HubConnection, connectionAttempts: number = 0) => {
        try {
            await connection.start();
            console.assert(connection.state === HubConnectionState.Connected);
            console.log('SignalR connection established');
        } catch (err) {
            console.assert(connection.state === HubConnectionState.Disconnected);
            console.error(`SignalR Connection Error, attempt ${connectionAttempts}.`, err);
            if(connectionAttempts < 5)
                setTimeout(() => signalRService.startSignalRConnection(connection, connectionAttempts + 1), 5000);
        }
    },

    setupSignalRConnection:(accessToken: string) => {
        const hubUrl = process.env.REACT_APP_RECONILE_API_URL + "/eventhub"
        const options : IHttpConnectionOptions = {
            accessTokenFactory: () => accessToken ?? "",

        };

        const connection = new HubConnectionBuilder()
            .withUrl(hubUrl, options)
            .withAutomaticReconnect()
            .withHubProtocol(new JsonHubProtocol())
            .configureLogging(LogLevel.Information)
            .build();

        connection.serverTimeoutInMilliseconds = 60000;

        // re-establish the connection if connection dropped
        connection.onclose((error: any) => {
            console.assert(connection.state === HubConnectionState.Disconnected);
            console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
        });

        connection.onreconnecting((error: any) => {
            console.assert(connection.state === HubConnectionState.Reconnecting);
            console.log('Connection lost due to error. Reconnecting.', error);
        });

        connection.onreconnected((connectionId: any) => {
            console.assert(connection.state === HubConnectionState.Connected);
            console.log('Connection reestablished. Connected with connectionId', connectionId);
        });

        signalRService.startSignalRConnection(connection);
        return connection;
    }
}
export default signalRService;