import LocalizedStrings from 'react-localization'

const allNorwegianLanguages = ['no', 'nb', 'nn', 'nb-NO']

type PageType = 'landing' | 'footer' | 'notfound'


const strings = (page: PageType) => {
  let pageStrings = null

  switch (page) {
    case 'landing':
      pageStrings = landing
      break

    case 'footer':
      pageStrings = footer
      break

    case 'notfound':
      pageStrings = notFound
      break

    default:
      pageStrings = landing
      break
  }

  const userLanguage = pageStrings.getInterfaceLanguage()

  if (allNorwegianLanguages.some((language) => language === userLanguage)) {
    pageStrings.setLanguage('no')
  }

  return pageStrings
}

const landing = new LocalizedStrings({
  en: {
    metaTitle: 'Automatic reconciliation - self-propelled accounting system from Aritma',
    headerTitle: 'Real-time',
    headerTitleUnderlined: 'reconciliation',
    loginButton: 'Sign in',
    learnMoreButton: 'Learn more',
    sectionTitle: 'How it works',
    reconcileSectionTitle: 'Automatic Reconciliation',
    reconcileSectionSubtitle: 'Automated and easy',
    reconcileSection:
      'Cut down on manual processes and save time by automatically reconciling your accounts. With Aritma Control you have an overview and full control of your data across all your accounts and clients. Automatic reconciliation is time and cost saving, and increases your efficiency. With an automatic accounting system, daily reconciliation is a necessity. With this, you can control your accounting daily and easily find the deviations.',
    cloudBasedSectionTitle: 'Fully integrated solution',
    cloudBasedSectionSubtitle: 'Integrated towards Visma.NET ERP',
    cloudBasedSection:
      'Aritma Control requires no server, and there are no start-up costs. It is easy to get started and the solution can be used from anywhere. From your home office, from the cabin or a sun bed! You will automatically get access to new versions and functionality, and you only pay for continuous use.',
    integratedSolutionsSectionTitle: 'Cloud-based solution',

    integratedSolutionsSectionSubtitle: 'Ready to use',
    integratedSolutionsSection:
      'The solution is fully integrated with your accounting system and your bank. It is always updated, and no manual import of transactions is necessary. Transactions from the bank and accounting system is retrieved automatically, and Aritma Control does the reconciliation for you. You can also book vouchers directly from the solution to your accounting system. The solution is integrated with most of the banks in the Nordics, and is fully integrated with Visma.net. We will continuously build integrations to new accounting systems.',
    technologySectionTitle: 'Technology and machine learning',
    technologySectionSubtitle: 'The solution of the future',
    technologySection:
      'Our solution is already among the best when it comes to reconciliation rules. Through machine learning the solution will get even smarter and will become able to automatically reconcile and book 100 % of the deviations between your bank and accounting system.',
    contactFormTitle: 'Contact us',
    contactFormSubtitle: `Want to know more about our solution? Get in touch and we'll show you!`,
    contactFormName: 'Name',
    contactFormEmail: 'Email',
    contactFormCompany: 'Company',
    contactFormPhoneNumber: 'Phone Number',
    contactFormMessage: 'Message',
    contactFormSubmit: 'Send message',
    contactFormSuccess:
      'Thanks for your message, we will contact you shortly.',
    contactFormError:
      'An error occurred while trying to send your message. Please try again later or contact us at support@zdata.no.',
  },
  no: {
    metaTitle: 'Automatisk avstemming – selvkjørende regnskapsystem fra Aritma',
    headerTitle: 'Avstemming',
    headerTitleUnderlined: 'i sanntid',
    loginButton: 'Logg inn',
    learnMoreButton: 'Finn ut mer',
    sectionTitle: 'Hvordan det fungerer',
    reconcileSectionTitle: 'Automatisk avstemming',
    reconcileSectionSubtitle: 'Automatisert og enkelt',
    reconcileSection:
      'Automatisk avstemming gjør at du sparer tid, reduserer kostnader og arbeider effektivt. Med et selvkjørende regnskapssystem er daglig avstemming et must slik at du på en enkel måte kan kontrollere regnskapsføringen fra dag til dag, og jobbe avviksbasert.',
    cloudBasedSectionTitle: 'Fullintegrert løsning',
    cloudBasedSectionSubtitle: 'Integrert mot Visma.NET ERP',
    cloudBasedSection:
      'Løsningen er fullintegrert med ditt regnskapssystem og din bankforbindelse. Alltid oppdatert uten manuell innlesning av transaksjoner og innlogging i nettbank. Transaksjoner fra regnskap og bank blir hentet automatisk, og løsningen avstemmer for deg. Du kan bokføre direkte fra løsningen for å behandle faste trekk i banken eller andre avvik mellom bank og regnskap.',
    integratedSolutionsSectionTitle: 'Skybasert løsning',
    integratedSolutionsSectionSubtitle: 'Klar til bruk',
    integratedSolutionsSection:
      'Reconcile krever ingen server, og det er ingen oppstartskostnader. Det er enkelt og komme i gang og løsningen kan brukes fra hvor som helst. Fra hjemmekontoret, fra hytten eller en solseng! Du har automatisk tilgang på nye versjoner og ny funksjonalitet, og du betaler kun for løpende bruk.',

    technologySectionTitle: 'Teknologi og maskinlæring',
    technologySectionSubtitle: 'Fremtidens løsning',
    technologySection:
      'Løsningen er allerede best i klassen når det kommer til avstemmingsregler. Gjennom maskinlæring vil løsningen bli enda smartere fremover og langt på vei være i stand til å automatisk avstemme og bokføre 100 % av avvikene mellom bank og regnskap.',
    contactFormTitle: 'Kontakt oss',
    contactFormSubtitle:
      'Vil du vite mer om løsningen vår? Ta kontakt, så viser vi deg!',
    contactFormName: 'Navn',
    contactFormEmail: 'E-post',
    contactFormCompany: 'Din bedrift',
    contactFormPhoneNumber: 'Telefonnummer',
    contactFormMessage: 'Melding',
    contactFormSubmit: 'Send melding',
    contactFormSuccess:
      'Tusen takk! Din beskjed er sendt. Vi tar kontakt med deg så snart vi kan.',
    contactFormError:
      'Vi beklager, det skjedde en teknisk feil. Vennligst prøv igjen litt senere eller kontakt oss på support@zdata.no.',
  },
})

const footer = new LocalizedStrings({
  en: {
    contactTitle: 'Get in touch',
    contactSubtitle: 'Questions or feedback?',
  },
  no: {
    contactTitle: 'Kom i kontakt',
    contactSubtitle: 'Spørsmål eller tilbakemelding?',
  },
})

const notFound = new LocalizedStrings({
  en: {
    title: 'Page not found!',
    subtitle: 'It seems we can’t find the page you are looking for.',
    goToHomePageButton: 'Go to homepage',
  },
  no: {
    title: 'Siden finnes ikke!',
    subtitle:
      'Beklager, vi finner ikke siden du ba om. Siden kan være flyttet eller slettet.',
    goToHomePageButton: 'Gå til hjemmesiden',
  },
})

export { strings }

