import { CardMedia } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import darkLogo from '../../images/aritma-logo-indigo.svg';
import whiteLogo from '../../images/aritma-logo-pink.svg';
import { selectTheme, ThemeType } from '../../store/reducers/themeSlice';
import topBarStyles from '../../styles/TopBarLayout';
import DarkThemeSlider from './DarkThemeSlider';
import ProfileMenu from './ProfileMenu';
import { useAuth } from 'react-oidc-context';

interface TopBarProps {
  children: React.ReactNode;
  open: boolean;
  handleDrawerOpen: () => void;
}

const TopBar = ({ children, open, handleDrawerOpen }: TopBarProps) => {
  const auth = useAuth()

  const classes = topBarStyles();
  const themeType = useSelector(selectTheme);

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <Grid container wrap="nowrap" alignItems="center" justifyContent="flex-start" spacing={2}>
          <Grid item>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.menuButtonHidden)}
              size="large">
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item className={classes.logoContainer}>
            <Link underline='none' component={RouterLink} to={'/'}>
              <CardMedia className={classes.logo} title="Aritma Logo" component="img" image={themeType === ThemeType.DARK_THEME ? whiteLogo : darkLogo} />
            </Link>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <DarkThemeSlider />
          </Grid>
          {auth.isAuthenticated &&
            <Grid item>
              <ProfileMenu />
            </Grid>
          }
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
export default TopBar;