import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ReportsState {
  createdReportId: string | undefined;
}

const initialState: ReportsState = {
  createdReportId: undefined
};

const reportsSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCreatedReportId: (state, action: PayloadAction<string | undefined>) => {
      state.createdReportId = action.payload;
    },
  }
});
export default reportsSlice.reducer

export const { setCreatedReportId } = reportsSlice.actions;
export const selectCreatedReport = (state: RootState) => state.reports.createdReportId;
