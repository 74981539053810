import makeStyles from '@mui/styles/makeStyles';

const drawerWidth = 200
const toolbarMinHeight = 55
const navigationDrawerStyles = makeStyles((theme) => ({
  toolbarIcon: {
    color: 'green',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: toolbarMinHeight,
    ...theme.mixins.toolbar
    // ...theme.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.secondary.main,
    border: "none",
    color: theme.palette.primary.contrastText,
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiButton-root': {
      color: theme.palette.secondary.contrastText,
    },
    '& .MuiIconButton-root': {
      color: theme.palette.secondary.contrastText,
    },
    '& li': {
      color: theme.palette.secondary.contrastText,
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}))
export default navigationDrawerStyles
