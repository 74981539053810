import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from "oidc-client-ts";
import OidcUser from '../models/interfaces/OidcUser';
import { RootState } from '../store';

const initialState: OidcUser = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<OidcUser>) => {
      return action.payload;
    }
  }
});

export const { setUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;

export const toOidcUser = (user: User): OidcUser => {
  return {
    id_token: user.id_token,
    session_state: user.id_token,
    access_token: user.access_token,
    refresh_token: user.refresh_token,
    token_type: user.token_type,
    scope: user.scope,
    profile: user.profile,
    expires_at: user.expires_at,
    state: user.state,
  };
}

export default userSlice.reducer;