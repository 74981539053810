import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { useSelector } from "react-redux";
import { IDENTITY_CONFIG } from './auth/AuthConst';
import Layout from './Layout';
import { DARK_THEME, LIGHT_THEME } from './store/constants/themeTypes';
import { selectTheme, ThemeType } from './store/reducers/themeSlice';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {

  const themeType = useSelector(selectTheme);
  const selectedTheme = useMemo(
    () => themeType === ThemeType.DARK_THEME ? DARK_THEME : LIGHT_THEME,
    [themeType]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={selectedTheme}>
        <CssBaseline />
          <AuthProvider {...IDENTITY_CONFIG}>
            <Layout />
          </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
export default App;