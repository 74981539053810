import { useAuth } from 'react-oidc-context';
import CenterSpinner from "../components/widgets/CenterSpinner";

interface PrivateRouteProps {
  children: React.ReactNode
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const auth = useAuth()
  
  if (auth.error) {
    return <p>Error authenticating</p>
  }
 

  if (!auth.isLoading && !auth.isAuthenticated) {
   localStorage.setItem("redirectUri", window.location.pathname);
    
   auth.signinRedirect()
  }


  if (auth.isLoading) {
    return <CenterSpinner />
  }

  return auth.isAuthenticated ? <>{children}</> : <CenterSpinner />
}

export default PrivateRoute;