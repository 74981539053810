import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from './DrawerItems';
import navigationDrawerStyles from '../../styles/NavigationDrawerStyles';
import useWidth from '../../utils/useWidth';
import { strings } from '../../utils/localization'


type Variant = 'temporary' | 'permanent' | 'persistent' | undefined

const isInHome = (path: string) => {
  return !path || path === '/';
}


interface NavigationDrawerProps {
  handleDrawerClose: () => void;
  open: boolean,
}

const NavigationDrawer = ({ handleDrawerClose, open }: NavigationDrawerProps) => {
  const width = useWidth();
  const location = useLocation();

  useEffect(() => {
    if (width === 'xs')
      setVariant('temporary');
    else
      setVariant(isInHome(location.pathname) ? 'temporary' : 'permanent');
  }, [location, width]);

  const classes = navigationDrawerStyles();
  const [variant, setVariant] = useState('temporary' as Variant);
  const [prevPath, setPrevPath] = useState(window.location.pathname);

  useEffect(() => {
    if (prevPath !== '/')
      handleDrawerClose();
    setPrevPath(location.pathname);
    if (isInHome(location.pathname)) {
      document.title = strings('landing').getString('metaTitle')
    } else {
      var pathname = location.pathname.replace(/\/$/, '');
      var title = pathname.substring(pathname.lastIndexOf('/') + 1);
      title = "ZData Control - " + title[0].toUpperCase() + title.substring(1);
      document.title = title;
    }
  }, [location])

  return (
    <React.Fragment>
      <Drawer
        variant={variant}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
          <MainListItems />
        <Divider />
      </Drawer>
    </React.Fragment>
  );
}
export default NavigationDrawer;