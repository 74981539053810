interface ReconcileTransactionsAdded {
  AccountId: string;
  ReconcileGroupId: string;
  AgreementId: string;
  TransactionIds: string[];
}
export default ReconcileTransactionsAdded;

export const isReconcileTransactionsAdded = (resource: any): resource is ReconcileTransactionsAdded => {
  return resource.AccountId !== undefined && resource.AgreementId !== undefined && resource.ReconcileGroupId !== undefined;
}