import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from '../models/interfaces/Account';
import OpenPost from '../models/interfaces/OpenPost';
import { RootState } from '../store';

interface AccountsState {
  selectedErpAccounts: Account[] | undefined;
  selectedBankAccounts: Account[] | undefined;
  canContinueAccountSelection: boolean;
  openErpPosts: OpenPost[] | undefined,
  openBankPosts: OpenPost[] | undefined
}

const initialState: AccountsState = {
  selectedErpAccounts: undefined,
  selectedBankAccounts: undefined,
  openErpPosts: undefined,
  openBankPosts: undefined,
  canContinueAccountSelection: false
};

const selectedAccountsSlice = createSlice({
  name: 'selectedAccounts',
  initialState,
  reducers: {
    setSelectedErpAccounts: (state, action: PayloadAction<Account[] | undefined>) => {
      state.selectedErpAccounts = action.payload;
    },
    setSelectedBankAccounts: (state, action: PayloadAction<Account[] | undefined>) => {
      state.selectedBankAccounts = action.payload
    },

    setOpenErpPosts: (state, action: PayloadAction<OpenPost[] | undefined>) => {
      state.openErpPosts = action.payload;
    },
    setOpenBankPosts: (state, action: PayloadAction<OpenPost[] | undefined>) => {
      state.openBankPosts = action.payload;
    },
    setCanContinueAccountSelection: (state, action: PayloadAction<boolean>) => {
      state.canContinueAccountSelection = action.payload;
    }
  }
});
export default selectedAccountsSlice.reducer

export const { setSelectedErpAccounts, setSelectedBankAccounts, setOpenErpPosts, setOpenBankPosts, setCanContinueAccountSelection } = selectedAccountsSlice.actions;

export const selectSelectedErpAccounts = (state: RootState) => state.selectedAccounts.selectedErpAccounts;
export const selectSelectedBankAccounts = (state: RootState) => state.selectedAccounts.selectedBankAccounts;
export const selectOpenErpPosts = (state: RootState) => state.selectedAccounts.openErpPosts;
export const selectOpenBankPosts = (state: RootState) => state.selectedAccounts.openBankPosts;

export const selectCanContinueAccountSelection = (state: RootState) => state.selectedAccounts.canContinueAccountSelection;
