import CssBaseline from '@mui/material/CssBaseline';
import { BrowserHistory } from "history";
import React, { useLayoutEffect, useState } from 'react';
import { BrowserRouterProps, Router } from "react-router-dom";
import NavigationDrawer from './components/navigation/NavigationDrawer';
import PageContent from './components/pageContent/PageContent';
import TopBar from './components/topBar/TopBar';
import layoutStyles from './styles/LayoutStyles';
import history from "./utils/browserHistory";

const Layout = () => {
  const classes = layoutStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <CustomRouter history={history}>
        <TopBar open={open} handleDrawerOpen={handleDrawerOpen}>
          Reconcile
        </TopBar>
        <NavigationDrawer
          open={open}
          handleDrawerClose={handleDrawerClose}
        />
        <PageContent />
      </CustomRouter>
    </div>
  );
}
export default Layout;

interface CustomRouterProps extends BrowserRouterProps {
  history: BrowserHistory;
}
export const CustomRouter = ({ basename, history, children }: CustomRouterProps) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), [history]);
  return (
    <Router
      navigator={history}
      location={state.location}
      navigationType={state.action}
      children={children}
      basename={basename}
    />
  );
};  