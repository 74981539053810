import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import CenterSpinner from '../components/widgets/CenterSpinner';
import { setUser, toOidcUser } from '../store/reducers/userSlice';
import store from '../store/store';

export const AuthHandler = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!auth.isLoading && auth.user) {
      store.dispatch(setUser(toOidcUser(auth.user)));

      const redirectUri = localStorage.getItem("redirectUri")?.toString();
      navigate(redirectUri ?? "/dashboard")
    }
  }, [auth.isLoading, auth.user, navigate])

  return <CenterSpinner />
}