import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeElement } from '../../utils/arrayHelpers';
import { AutoFocus, SearchState } from "../models/interfaces/Search";
import { RootState } from "../store";

interface SearchColumnsState {
    search : SearchState[];
    autoFocus : AutoFocus | undefined;
}

const initialState : SearchColumnsState = {
    search : [] as SearchState[],
    autoFocus : undefined
}

const searchSlice = createSlice({
    name : 'search',
    initialState,
    reducers: {
        setSearchState : (state, action : PayloadAction<SearchState>) => {
            const currentSearchState = state.search.find((x : SearchState) => x.id === action.payload.id);
            if (currentSearchState){
                currentSearchState.text = action.payload.text;
            }else{
                state.search.push(action.payload)
            }
        },
        removeSearchState : (state, action  : PayloadAction<string>) => {
            const toBeRemoved = state.search.find((x : SearchState) => x.id === action.payload);
            if (toBeRemoved) {
                const newSearchState = removeElement(state.search, toBeRemoved);
                state.search  = newSearchState;
            }
        },
        setAutofocusState : (state, action : PayloadAction<AutoFocus | undefined>) => {
            state.autoFocus = action.payload;
        }

    }
});
export default searchSlice.reducer


export const {setSearchState, removeSearchState, setAutofocusState} = searchSlice.actions;
export const selectSearchState = (state : RootState) => state.search.search;
export const selectAutofocusState = (state : RootState) => state.search.autoFocus;